// src/components/Offerings.js
import React from 'react';
import './Offerings.css';

const Offerings = () => {
  return (
    <section id="offerings">
      <h2>Offerings</h2>
      <div className="offerings-content">
        <h3>Intuitive Psychotherapy</h3>
        <p>
          Intuitive Psychotherapy is integration of traditional western psychotherapy with energetic and psychic arts to help foster clarity and awareness. In addition to my training as a Psychotherapist i have spent over twenty years honing in my skills as an energy reader and psychic. As an Intuitive Psychotherapist I bring in  tarot reading, aura reading, vedic astrology in amalgation with psychological counselling skills to help you to probe deeper into your issues and bring more clarity to your confusions. As a Certified practitioner of Neuro Linguistic Programming, Hypnotherapy,Past Life Regression Therapy, Inner Child Therapy, Expressive Arts Therapy and various energy healing modalities like Reiki, Pranic Healing, Theta Healing, Healing Touch, Therapeutic Touch & Core Shamanism i bring the skills of these modalities in our session together to create safe space for change and healing  at physical, mental and emotional levels as needed.        
        </p>
        <h3>Individual Sessions</h3>
        <ul>
          <li>Tarot Card and Angel Reading and Counselling</li>
          <li>Clairvoyant Aura Reading</li>
          <li>Energy Healing Session</li>
          <li>Hypnotherapy</li>
          <li>Past Life Regression Therapy</li>
          <li>Space Clearing</li>
        </ul>
      </div>
    </section>
  );
};

export default Offerings;
