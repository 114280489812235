// src/components/Workshops.js
import React from 'react';
import './Workshops.css';

const Workshops = () => {
  return (
    <section id="workshops">
      <h2>Workshops</h2>
      <div className="workshops-content">
        <h3>Practitioner Trainings Offered:</h3>
        <ul>
          <li>
            <strong>Reiki Healing</strong> <br/>
              Reiki is a japanese art of healing that promotes relaxation on physical,mental and emotional levels assisting the body and mind to heal itself. 
              I have been trained in various lineages and traditions of Reiki both from the west and japan,and currently teach in tradition of William Lee Rand.
          </li>
          <li>
            <strong>Angel Therapy</strong> <br/>
              I have trained with Doreen Virtue PhD and been certified as Angel Therapy Practitioner®️ and Angel Intuitive™️ by her. 
              Having worked with the Angels for over a decade,I bring in my personal experience and her teachings in this training to help you understand the Angelic Realms and how to work with them for self and others.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Workshops;
