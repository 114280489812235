import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [status, setStatus] = useState(''); // For displaying status messages
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to manage submission

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent multiple submissions
    if (isSubmitting) return;

    setIsSubmitting(true); // Disable the submit button

    try {
      const response = await fetch('https://hrn-api.onrender.com/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(formData)
      });

      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        const data = await response.json();
        setStatus(`Error: ${data.error}`);
      }
    } catch (error) {
      setStatus('An error occurred while sending your message.');
    } finally {
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  return (
    <section id="contact">
      <h2>Contact</h2>
      <div className="contact-content">
        <p>We usually have a two-week waiting period. Feel free to send us a message below.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              required
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              rows="5"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        {status && <p>{status}</p>}
        <ul>
          <li>
            <a href="https://wa.me/+9779709073752">
              <i className="fa fa-whatsapp"></i> +977 970-9073752 (WhatsApp only)
            </a>
          </li>
          <li>
            <a href="mailto:intuitivetherapistnepal@gmail.com">
              <i className="fa fa-envelope"></i> intuitivetherapistnepal@gmail.com
            </a>
          </li>
          <li>(Currently on a break!)</li>
        </ul>
      </div>
    </section>
  );
};

export default Contact;
