import './Home.css';
import video_theme from './assets/theme.mov';

const Home = ({ handleNavigation }) => {
  return (
    <section className="home">
      {/* Left Content Section */}
      <div className="home-content">
        <h1>
          I will not rescue you, for you are not powerless<br />
          I will not fix you, for you are not broken <br />
          I will not heal you, for I see your wholeness <br />
          I will walk with you through the darkness,<br />
          as you remember your light{' '}<br />
          <span>all of you is welcomed and embraced here.</span>
        </h1>
        <p>Welcome to my world of intuitive psychotherapy.</p>
        <div className="social-media">
          {/* Call handleNavigation with 'contact' to navigate */}
          <button onClick={() => handleNavigation('contact')}>Book Appointment</button>
        </div>
      </div>

      {/* Right Video Section */}
      <div className="home-video">
        <video autoPlay muted loop>
          <source src={video_theme} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};

export default Home;
