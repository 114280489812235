import React, { useState, useEffect } from 'react';
import './Testimonials.css';

const Testimonials = () => {
  const testimonials = [
    {
      message: `"I contacted Ajay sir when my father was in ventilator because of Covid. Hospital told us there was little hope recovering. After the consultation we were told do some puja and also book for healing for my father. My father recovered in less than a week. I belive it was due to medical science and guidance of ajay sir. Thank you."`,
      name: 'Prabhat Shrestha',
      job: 'Student',
      location: 'Kathmandu',
    },
    {
      message: `"i have been coming to dai for years now.he helps me make decisions and plans.i like his style of explaining why something is like it is in my life and helping me see my problems from various angles.his analysis has helped me boost my business more than anyone else and this is why i visit him."`,
      name: 'Nirakar Palikhe',
      job: 'Entrepreneur',
      location: 'Nepal',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [testimonials.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section id="testimonials">
      <h2>Testimonials</h2>
      <div className="carousel">
        {testimonials.map((t, index) => (
          <div
            key={index}
            className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
          >
            <p className="message">{t.message}</p>
            <p className="author">
              <strong>{t.name}</strong>,<br/> {t.job} from {t.location}
            </p>
          </div>
        ))}
        <button className="carousel-control prev" onClick={prevSlide}>
          &#8249;
        </button>
        <button className="carousel-control next" onClick={nextSlide}>
          &#8250;
        </button>
      </div>
    </section>
  );
};

export default Testimonials;
