import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from './assets/ajay.jpg';
import Home from '../Home/Home';
import AboutMe from '../About/About';
import Offerings from '../Offerings/Offerings';
import Workshops from '../Workshops/Workshops';
import Testimonials from '../Testimonials/Testimonials';
import Contact from '../Contact/Contact';
import Preloader from '../Preloader/Preloader';

const Navbar = () => {
  const [currentPage, setCurrentPage] = useState('home');
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const renderComponent = () => {
    switch (currentPage) {
      case 'home':
        return <Home handleNavigation={handleNavigation} />;
      case 'about':
        return <AboutMe />;
      case 'offerings':
        return <Offerings />;
      case 'workshops':
        return <Workshops />;
      case 'testimonials':
        return <Testimonials />;
      case 'contact':
        return <Contact />;
      default:
        return <Home handleNavigation={handleNavigation} />;
    }
  };

  const handleNavigation = (page) => {
    setCurrentPage(page);
    setIsMobileMenuOpen(false); // Close mobile menu on navigation
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <nav>
            <div className="logo" onClick={() => handleNavigation('home')}>
              <img src={logo} alt="Logo" />
              <p>
                Ajay Karki<span>Intuitive Therapist</span>
              </p>
            </div>
            <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
              <li onClick={() => handleNavigation('home')}>Home</li>
              <li onClick={() => handleNavigation('about')}>About Me</li>
              <li onClick={() => handleNavigation('offerings')}>Offerings</li>
              <li onClick={() => handleNavigation('workshops')}>Workshops</li>
              <li onClick={() => handleNavigation('testimonials')}>Testimonials</li>
              <li onClick={() => handleNavigation('contact')}>Contact</li>
            </ul>
            <div className="mobile-menu" onClick={toggleMobileMenu}>
              <i className={`fa ${isMobileMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
            </div>
          </nav>
          <main>{renderComponent()}</main>
        </>
      )}
    </>
  );
};

export default Navbar;
