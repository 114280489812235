// src/components/About.js
import React from 'react';
import './About.css';

const AboutMe = () => {
  return (
    <section id="about">
      <div className="container-about">
        <h2>About Me</h2>
        <div className="about-content">
          <div className="about-image"></div>
          <div className="about-text">
            <p>
                Namaste,<br/>my name is Ajay Karki and I am an Intuitive, Psychotherapist, Teacher and Healing Practitioner based in Kathmandu, Nepal. I have studied with some of the best teachers and traditions of East and West to offer my services as a practitioner and teacher of Vedic Astrology, Tarot Counselling, Reiki Healing, Aura Readings, NLP, Hypnotherapy and Past Life Regression Therapy. My practise draws upon the foundation of both psychotherapy and psychic arts blended with mystical and healing traditions and practises from across the globe and culture. Along with background in psychodynamic and gestalt psychotherapy I am an initiate of the shamanistic folk tantra based spiritual practises of Kathmandu Valley where I was born and raised.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
