// src/components/Preloader.js
import React from 'react';
import './Preloader.css';

const Preloader = () => {
  return (
    <div id="preloader">
      <div className="loader"></div>
      <div className="loader-text">&nbsp; Loading</div>
    </div>
  );
};

export default Preloader;
