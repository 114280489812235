import React from 'react';
import Navbar from './components/Navbar/Navbar';
import './App.css';

const App = () => {
  return (
      <Navbar />
  );
};

export default App;
